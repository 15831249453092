<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("APPLYLIST.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("APPLYLIST.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5>1. {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${Action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${Action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5 v-if="action == 'insert'">2. {{ $t("COMMON.SETCATEGORY") }}</h5>
            <h5 v-if="action == 'update'">1. {{ $t("COMMON.EDITCATEGORY") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="12" class="category">
            <div>
              <!-- <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.PERMISSIONSETTING') }}<span class="text-danger">*</span></label> -->
              <TreeSelect2 :items="categorys" :icon="false" v-model:selected="Form.category.value" :open="true">
                
              </TreeSelect2>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5 v-if="action == 'insert'">3. 設定標籤</h5>
            <h5 v-if="action == 'update'">2. 設定標籤</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="6">
            <MDBRow>
              <MDBCol md="12">
                <div class="mb-3">
                  <template  v-for="(item ,k) in ProductTag" :key="k">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-bind:id="`${Action}-tag-${k}`" :value="item.uuid" v-model="Form.tag.value">
                      <label class="form-check-label" v-bind:for="`${Action}-tag-${k}`">
                        {{ item.name[language] }}
                      </label>
                    </div>
                  </template>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5 v-if="action == 'insert'">4. {{ $t("COMMON.SETCOMMODITY") }}</h5>
            <h5 v-if="action == 'update'">3. {{ $t("COMMON.EDITCOMMODITY") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="6">
            <MDBBtn type="button" color="primary" @click="product_modal = true" class="mb-3">加入商品</MDBBtn>
            <MDBModal
              tabindex="-1"
              v-model="product_modal"
              size="lg"
            >
              <MDBModalHeader>
                <MDBModalTitle>加入商品</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBContainer>
                  <MDBRow class="mb-3">
                    <MDBRow>
                      <MDBCol md="4">
                        <MDBInput  v-model="where.amy" />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput  v-model="where.jason" />
                      </MDBCol>
                    </MDBRow>
                    <div style="height:65vh;overflow-y: auto;">
                      <MDBTable
                        class="table datatable-table"
                        responsive
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>商品名稱</th>
                            <th>使用版本</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="tmp_commodity.length > 0">
                            <tr v-for="(row ,k) in tmp_commodity" :key="k" >
                              <td scope="row"><input type="checkbox" v-model="Form.commodity.value" :value="row.uuid"></td>
                              <td scope="row">{{row.name[Form.lang.value].name}}</td>
                              <td scope="row">{{row.version[Form.lang.value].name}}</td>
                            </tr> 
                          </template>
                          <tr v-else>
                            <td colspan="9" class="text-center">無資料</td>
                          </tr>
                        </tbody>
                      </MDBTable>
                    </div>
                  </MDBRow>
                </MDBContainer>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn @click="product_modal = false">{{ $t("COMMON.CLOSE") }}</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBRow class="mb-3">
              <MDBCol md="12">
                <MDBRow>
        <!--           <div class="col-md-6">
                    <fieldset class="border p-2">
                      <legend  class="float-none w-auto h6">產品</legend>
                      <template  v-for="(item ,k) in Commodity.filter(item => item.type == 1)" :key="k">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-bind:id="`${Action}-commodity-${k}`" :value="item.value" v-model="Form.commodity.value">
                          <label class="form-check-label" v-bind:for="`${Action}-commodity-${k}`">
                            {{ item.name }}
                          </label>
                        </div>
                      </template>  
                    </fieldset>
                  </div> -->
                  <div class="col-md-6">
                    <fieldset class="border p-2">
                      <legend  class="float-none w-auto h6"></legend>
                      <template  v-for="(item ,k) in tmp_commodity" :key="k">
                        <div class="form-check" v-if="Form.commodity.value.indexOf(item.uuid) >= 0">
                          <input class="form-check-input" type="checkbox" v-bind:id="`${Action}-commodity-${k}`" :value="item.uuid" v-model="Form.commodity.value">
                          <label class="form-check-label" v-bind:for="`${Action}-commodity-${k}`">
                            {{ item.name[Form.lang.value].name }}({{(typeof item.version == 'object')?item.version[Form.lang.value].name:item.version}})
                          </label>
                        </div>
                      </template>
                    </fieldset>
                  </div>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol>
            <h5 v-if="action == 'insert'">5. {{ $t("COMMON.SETAPPLY") }}</h5>
            <h5 v-if="action == 'update'">4. {{ $t("COMMON.EDITAPPLY") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" >
          <MDBCol md="12">
            <MDBRow class="g-3">
              <MDBCol md="12">
                <MDBRow class="g-3">
                  <MDBCol md="12">
                    <label class="form-label text-dark" :data-tag="`${Action}-banner`">{{ $t('COMMON.APPLYBANNER') }}<span class="text-danger">*</span></label>
                    <span class="text-danger">{{ bannerError }}</span>  
                  </MDBCol>
                  <MDBCol md="4">
                    <div  v-if="!edit_banner" class="edit-slim ">
                      <img :src="banner_url" class="mb-2">
                      <MDBBtn color="primary" type="button" @click="EditBanner">{{ $t("COMMON.EDIT") }}</MDBBtn>
                    </div>
                    <Slim v-model="Form.banner.value" label="請上傳 330 x 170 尺寸的圖片" v-model:url="banner_url" :width="BannerImage.width" :height="BannerImage.height" v-else></Slim>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <!-- 輪播圖 -->
              <MDBCol md="12">
                <MDBRow class="g-3">
                  <MDBCol md="12">
                    <label class="form-label text-dark" :data-tag="`${Action}-carousel`">{{ $t('COMMON.APPLYCAROUSEL') }}<span class="text-danger">*</span></label>
                    <span class="text-danger">{{ carouselError }}</span>  
                  </MDBCol>
                  <MDBCol md="12">
                    <MDBBtn type="button" color="primary" @click="carousel_modal = true">{{ $t("COMMON.SETCAROUSEL") }}</MDBBtn>  
                  </MDBCol>
                  <MDBCol md="6">
                    <swiper
                      :slides-per-view="1"
                      navigation
                      :pagination="{'clickable': true}"
                    >
                      <swiper-slide v-for="(carousel, k) in Form.carousel.value" :key="k">
                        <img :src="carousel.url">
                        <div class="swiper-button mt-2">
                          <MDBBtn color="primary" type="button" @click="EditCarousel(k)">{{ $t("COMMON.EDIT") }}</MDBBtn>
                          <MDBBtn color="danger" type="button" @click="DeleteCarousel(k)">{{ $t("COMMON.DELETE") }}</MDBBtn>  
                        </div>
                      </swiper-slide>
                    </swiper>
                  </MDBCol>
                </MDBRow>
                <ProductCarouselSelect v-model="carousel_modal" :carousel="Form.carousel.value" action="insert"></ProductCarouselSelect>
                <ProductCarouselSelect v-model="edit_carousel_modal" :carousel="Form.carousel.value" action="update" :EditItem="EditCarouselKey"></ProductCarouselSelect>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark" :data-tag="`${Action}-product_number`">{{ $t('COMMON.APPLYNUMBER') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="product_number" :placeholder="$t('COMMON.APPLYNUMBER')" v-model="Form.product_number.value">
                  <span class="text-danger">{{ product_numberError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12" >
                <div class="mb-3">
                  <div>
                    <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.APPLYSTATUS') }}<span class="text-danger">*</span></label>  
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-1`" value="1" v-model="Form.status.value">
                    <label class="form-check-label" v-bind:for="`${Action}-status-1`">
                      {{ $t('COMMON.PUTON') }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-0`" value="0" v-model="Form.status.value">
                    <label class="form-check-label" v-bind:for="`${Action}-status-0`">
                      {{ $t('COMMON.PUTOFF') }}
                    </label>
                  </div>
                  <span class="text-danger">{{ statusError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark" :data-tag="`${Action}-name`">{{ $t('COMMON.NAME') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.NAME')" v-model="Form.name.value">
                  <span class="text-danger">{{ nameError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark" :data-tag="`${Action}-description`">{{ $t('COMMON.APPLYDESCRIPTION') }}<span class="text-danger">*</span></label>
                  <textarea class="form-control" rows="3" v-model="Form.description.value" :placeholder="$t('COMMON.APPLYDESCRIPTION')"></textarea>
                  <span class="text-danger">{{ descriptionError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark" :data-tag="`${Action}-illustrate`">購買提醒</label>
                  <textarea class="form-control" rows="3" v-model="Form.illustrate.value" placeholder="購買提醒"></textarea>
                  <span class="text-danger">{{ illustrateError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <Ckeditor5 label="課程介紹" v-model="Form.standard.value"></Ckeditor5>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t(`COMMON.SORT`) }}</label>
                  <input type="number" class="form-control" name="name" :placeholder="$t(`COMMON.SORT`)" v-model="Form.sort.value">
                  <span class="text-danger">{{ sortError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.APPLYDOWNLOADS') }}</label>
                  <div>
                    <a href="javascript:void(0)" @click="AddDownload"><MDBIcon icon="plus" iconStyle="fas" />{{ $t(`APPLYLIST.ADD-APPLYDOWNLOADS`) }}</a>
                  </div>
                </div>
                <template v-for="(download,k) in Form.downloads.value" :key="k">
                  <MDBRow class="mb-3" v-if="(typeof Form.downloads.value[k] != 'undefined')">
                    <MDBCol md="3">
                      <MDBInput
                        :label="$t(`COMMON.FILENAME`)"
                        type="text"
                        v-model="Form.downloads.value[k].name"
                      />
                    </MDBCol>
                    <MDBCol md="2">
                      <select class="form-select" v-model="Form.downloads.value[k].commodity">
                        <option value="">請選擇產品</option>
                        <template v-for="(commodity, key) in (Form.commodity.value)" :key="key">
                          <option :value="commodity" v-if="Commodity.filter(item => {return item.uuid == commodity}).pop()">{{ Commodity.filter(item => {return item.uuid == commodity}).pop().name[Form.lang.value].name }}</option>
                        </template>
                      </select>
                    </MDBCol>
                    <MDBCol md="2">
                      <MDBDatepicker label="檔案日期" format="YYYY/MM/DD" v-model="Form.downloads.value[k].date"></MDBDatepicker>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        :label="$t(`COMMON.FILELINK`)"
                        type="text"
                        v-model="Form.downloads.value[k].link"
                      />
                    </MDBCol>
                    <MDBCol md="1">
                      <a @click="DeleteDownload(k)" data-bs-toggle="tooltip" :title="$t('APPLYLIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
                        <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
                      </a>
                    </MDBCol>
                    
                  </MDBRow>
                </template>
              </MDBCol>
              <div class="row mt-3">
                <label class="form-label text-dark" >關鍵字</label>
                <template v-for="(value,name) in Form.seo.value" :key="name">
                  <div class="col-md-4">
                    {{ $t(`SEO.${name}`) }} :<input type="text" class="form-control mb-1" :placeholder="$t(`SEO.${name}`)" v-model="Form.seo.value[name]">
                  </div>
                </template>
              </div>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        
        
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .image-modal {
    height: 85vh;
    overflow-y: auto;
  }
  .edit-slim img {
    width: 100%;
  }
</style>

<script>
import { MDBInput, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBCardHeader, MDBDatepicker,MDBModalBody, MDBModalFooter, MDBModal, MDBModalHeader, MDBModalTitle, MDBContainer, MDBTable } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, reactive, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import Ckeditor5 from '@/components/Plugin/Ckeditor5';
import TreeSelect2 from '@/components/TreeSelect2';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Slim from '@/components/Plugin/Slim';
import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, {
  Navigation
} from 'swiper/core';

SwiperCore.use([Navigation]);

import ProductCarouselSelect from '@/components/Elements/ProductCarouselSelect';

export default {
  name: "ApplyForm",
  components: {
    MDBInput,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    TreeSelect2,
    Ckeditor5,
    Swiper,
    SwiperSlide,
    Slim,
    ProductCarouselSelect,
    MDBDatepicker,
    MDBModalBody,
    MDBModalFooter,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBContainer,
    MDBTable
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  inject: ["ProductCategory","Commodity",'ProductTag'],
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const languages = i18nService.languages;
    const language = i18nService.getActiveLanguage();
    const categorys = reactive({});
    const banner_modal = ref(false);
    const SubmitLoading = ref(false);
    const edit_banner = ref(false);
    const banner_url = ref("");
    const carousel_modal = ref(false);
    const edit_carousel_modal = ref(false);
    const EditCarouselKey = ref("");
    const product_modal = ref(false);
    const tmp_commodity = ref([]);
    const where = reactive({
        amy: "",
        jason: "",
      })
    const BannerImage = reactive({
      width: 330,
      height: 170,
    });

    const basicSchema = {
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      category: yup.array().required().label(i18n.t("HEADER.APPLYCATEGORY")),
      banner: yup.string().required().label(i18n.t("COMMON.APPLYBANNER")),
      carousel: yup.array().min(1).label("課程輪播圖"),
      product_number: yup.string().required().label(i18n.t("COMMON.PRODUCTNUMBER")),
      name: yup.string().required().label("名稱"),
      description: yup.string().required().label(i18n.t("COMMON.APPLYDESCRIPTION")),
      tag: yup.array().label(i18n.t("COMMON.APPLYTAG")),
      standard: yup.string().label(i18n.t("COMMON.APPLYDESCRIPTION")),
      downloads: yup.array().label(i18n.t("COMMON.APPLYDOWNLOADS")),
      commodity: yup.array().label(i18n.t("COMMON.APPLYDOWNLOADS")),
      status: yup.number().required().label(i18n.t("COMMON.PRODUCTSTATUS")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      illustrate: yup.string().label("售價說明"),
      seo: yup.object().label("關鍵字"),
    };

    let ExtendSchema = {};
    if(Action.value == "insert") {
      ExtendSchema = {
        
      };  
    }else{
      ExtendSchema = {
        
      };
    }
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm, errors, isSubmitting  } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        category: [],
        downloads: [],
        commodity: [],
        carousel: [],
        tag: [],
        lang: i18nService.getActiveLanguage(),
        sort: 0,
        status: 0,
        illustrate: "",
        seo: {
          title:'',
          keyword:'',
          content:'',
        }
      }
    });

    const { value: product_number, errorMessage: product_numberError } = useField('product_number');
    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: category, errorMessage: categoryError } = useField('category');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: description, errorMessage: descriptionError } = useField('description');
    const { value: standard, errorMessage: standardError } = useField('standard');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: downloads, errorMessage: downloadsError } = useField('downloads');
    const { value: commodity, errorMessage: commodityError } = useField('commodity');
    const { value: banner, errorMessage: bannerError } = useField('banner');
    const { value: carousel, errorMessage: carouselError } = useField('carousel');
    const { value: tag, errorMessage: tagError } = useField('tag');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: illustrate, errorMessage: illustrateError } = useField('illustrate');
    const { value: seo, errorMessage: seoError } = useField('seo');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        let form = CommonService.MakeForm(values);
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Apply", form).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            // banner_url.value = "";
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        let form = CommonService.MakeForm(values);
        form.append('_method', 'PUT');
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Apply/" + props.uuid ,form).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const EditBanner = function() {
      edit_banner.value = true;
      banner.value = "";
    }

    const Form = {
      lang,
      banner,
      category,
      product_number,
      name,
      description,
      standard,
      status,
      downloads,
      commodity,
      carousel,
      tag,
      sort,
      illustrate,
      seo,
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
      edit_banner.value = false;
    };

    const AddDownload = function(){
      Form.downloads.value.push({
        name: "",
        link: "",
        commodity: "",
        date: ""
      })
    };

    const DeleteDownload = function(index){
      Form.downloads.value = Form.downloads.value.filter(function(item,key) {
        return key !== index;
      })
    };

    const EditCarousel = function(index) {
      EditCarouselKey.value = index;
      edit_carousel_modal.value = true;
    };

    const DeleteCarousel = function(index) {
      Form.carousel.value = Form.carousel.value.filter(function(item,key) {
        return key !== index;
      })
    };

    return {
      Action,
      Model,
      banner_modal,
      carousel_modal,
      SubmitLoading,
      Form,
      langError,
      product_numberError,
      categoryError,
      nameError,
      descriptionError,
      standardError,
      statusError,
      downloadsError,
      commodityError,
      bannerError,
      carouselError,
      sortError,
      tagError,
      illustrateError,
      seoError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      language,
      Close,
      AddDownload,
      DeleteDownload,
      categorys,
      edit_banner,
      banner_url,
      EditBanner,
      edit_carousel_modal,
      EditCarousel,
      EditCarouselKey,
      DeleteCarousel,
      BannerImage,
      tmp_commodity,
      where,
      product_modal,
      errors,
      isSubmitting ,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
    Model: function(val){
      if(val && this.action == "insert"){
        this.SetCategory(this.language);
      }
    },
    "Form.lang.value": function(val){
      this.SetCategory(val);
    },
    "where.amy": function() {
      this.commodityFilter();
    },
    "where.jason": function() {
      this.commodityFilter();
    },
    isSubmitting : function() {
      let fields = Object.keys(this.errors);
      if(fields.length > 0) {
        var element = document.querySelector(`[data-tag="${this.action}-${fields[0]}"]`);
        if(element) {
          var top = element.offsetTop;
          document.querySelector('main').scrollTo(0,top);
        }
      }
    }
  },
  methods: {
    commodityFilter() {
      var amy = new RegExp(this.where.amy);
      var jason = new RegExp(this.where.jason);
      this.tmp_commodity = this.Commodity.filter((item) => {
        let amy_result = true;
        let jason_result = true;
        if(this.where.amy) {
          amy_result = ( item.name[this.Form.lang.value].name.match(amy)  || item.version[this.Form.lang.value].name.match(amy)) ;
        }
        if(this.where.jason) {
          jason_result = (item.name[this.Form.lang.value].name.match(jason) || item.version[this.Form.lang.value].name.match(jason)) ;
        }
        return amy_result && jason_result && item.type == 2;
      });
    },
    GetDetail() {
      this.resetForm();
      this.Form.category.value = [];
      this.Form.commodity.value = [];
      this.Form.carousel.value = [];
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Apply", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "banner":
                      response.data.data.banner.map(item => {
                        this.Form.banner.value = item.id;
                        this.banner_url = item.url;
                      });
                    break;
                    case "carousel":
                      response.data.data.carousel.map(item => {
                        this.Form.carousel.value.push({image:item.id,sort:item.pivot.order,url:item.url});
                      });
                    break;
                    case "category":
                      response.data.data[k].map(item => {
                        this.Form[k].value.push(item.uuid);
                      });
                    break;
                    case "commodity":
                      this.Form.commodity.value = response.data.data.commodity.map(item => item.uuid);
                    break;
                    case "tag":
                      this.Form.tag.value = response.data.data.tag.map(item => item.uuid);
                    break;
                    case "downloads":
                      this.Form[k].value = response.data.data[k];
                    break;
                    case "seo":
                      this.Form[k].value = response.data.data[k];
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
    SetCategory(lang) {
      this.ProductCategory.map(categroy => {
        if(typeof categroy.name[lang] != "undefined") {
          this.categorys[categroy.uuid] = {
            name: categroy.name[lang].name,
            id: categroy.uuid,
            children: []
          };
          for (let k in categroy.sub ) {
            if(typeof categroy.sub[k].name[lang] != "undefined") {
              this.categorys[categroy.uuid].children.push({
                id: categroy.sub[k].uuid,
                name: categroy.sub[k].name[lang].name
              });  
            }
          }
        }
      })
    }
  },
  mounted: function() {
    this.tmp_commodity = this.Commodity;
  }
};
</script>